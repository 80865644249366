
import Vue from 'vue';
import { FacebookLoginResponse } from '../../types/ads';
import { getCssVar } from '@c360/ui';

export default Vue.extend({
  components: {
    AppGlobals: () => import('../global/globals.vue'),
    MainFooter: () => import('../global/footer.vue'),
    HeaderLogoC360: () => import('../components/toolbar/headerLogoC360.vue'),
  },
  data: () => ({
    authChecked: false,
    complete: false,
    loggedIn: false,
    saving: false,
    checkStatus: false,
    nextStep: false,
    advertiserId: '',
    gaData: null,
    error: '',
    value: 0,
    showIntro: true,
    fbError: null,
    credentials: null,
    facebookAuth: null,
  }),
  created() {
    window.fbAsyncInit = (): void => {
      window.FB.init({
        appId: '504308743599681',
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v21.0',
      });

      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      let js = null;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  },
  mounted(): void {
    if (!this.$route.query.aid) {
      setTimeout(() => {
        this.authChecked = true;
        this.$store.dispatch('showError', 'not a valid landing page');
      }, 1000);
      return;
    }
    this.advertiserId = this.$route.query.aid;
  },
  computed: {
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
    isSaving(): boolean {
      return this.saving;
    },
    progressText(): string | null {
      if (this.error) return 'Something went wrong';
      if (!this.authChecked) return 'Checking status...';
      if (this.saving) return 'Saving...';
      if (this.checkStatus) return 'Checking Google Connection...';
      if (this.nextStep) return 'Syncing to Google Account...';
      return null;
    },
    themeColor(): string {
      return getCssVar('--c360-color-compulse');
    },
  },
  methods: {
    async saveFacebookAuto(fbResponse: FacebookLoginResponse): Promise<void> {
      const options = {
        userId: fbResponse.authResponse.userID,
        accessToken: fbResponse.authResponse.accessToken,
        agencyId: this.$route.query.aid,
      };

      try {
        const response = await this.$store.dispatch('agencyAdmin/addFacebookCredential', options);
        if (response) {
          this.credentials = response;
          this.$emit('connections');
          this.complete = true;
          setTimeout(() => {
            window.close();
          }, 3000);
        }
      } catch (exp) {
        this.fbError = exp.message;
      }
    },
    login(): void {
      const requiredPermissions = [
        'ads_read',
        'business_management',
        // 'read_insights',
        // 'pages_read_engagement',
        // 'pages_read_user_content',
      ];
      window.FB.login(
        (response: FacebookLoginResponse) => {
          // eslint-disable-next-line no-console
          console.log(response);
          if (response.status === 'connected') {
            this.response = response;
            this.fbError = null;
            this.facebookAuth = response;
            // check to see if we have the required permissions
            if (
              response.authResponse.grantedScopes &&
              requiredPermissions.every(scope => response.authResponse.grantedScopes.split(',').includes(scope))
            ) {
              this.saveFacebookAuto(response);
            } else {
              this.fbError = 'Insufficient permissions';
            }
          }
        },
        {
          auth_type: 'rerequest',
          return_scopes: true,
          scope: requiredPermissions.join(','),
        },
      );
    },
    tryAgain(): void {
      this.clear();
      this.login();
    },
    clear(): void {
      this.error = '';
      this.gaData = null;
      this.showIntro = true;
      this.nextStep = false;
      this.value = 0;
    },
  },
});
