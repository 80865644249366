var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[_c('v-main',{staticClass:"pt-4 bg-c360",attrs:{"id":"grantAccessPage"}},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md8":"","lg8":""}},[_c('div',{staticClass:"layout column align-center mb-4"},[_c('header-logo-c360')],1),_c('v-card',{staticClass:"mx-auto pa-3 elevation-0",attrs:{"max-width":"580"}},[(_vm.isSaving && !_vm.error)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),(
                _vm.error || _vm.$store.state.linearAdmin.linearAdminError || _vm.$store.state.linearAdmin.googleAnalyticsError
              )?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error || _vm.$store.state.linearAdmin.linearAdminError || _vm.$store.state.linearAdmin.googleAnalyticsError)+" Please contact your representative.")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[(_vm.isSaving)?_c('v-card-text',[_c('h2',{staticClass:"flex mb-4 primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.progressText))]),(
                      _vm.$store.state.linearAdmin.googleAnalyticsError ||
                      _vm.$store.state.linearAdmin.linearAdminError ||
                      _vm.error
                    )?_c('v-card-actions',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.tryAgain}},[_vm._v("Try Again")])],1):_vm._e()],1):_vm._e(),(_vm.complete)?_c('v-card-text',[_c('h2',{staticClass:"flex mb-4 primary--text font-weight-bold"},[_vm._v("Thank You!")]),_c('p',[_vm._v("Your account is now linked. You may close this window.")])]):_c('v-card-text',[(!_vm.$route.query.aid)?_c('div',[_c('h1',{staticClass:"flex mb-4 primary--text font-weight-bold"},[_vm._v("Page not Found")]),_c('p',[_vm._v("Please check the email you received, this link doesn't appear to be valid")])]):(_vm.showIntro)?_c('div',[_c('h1',{staticClass:"flex mb-4 primary--text font-weight-bold"},[_vm._v("Link Facebook Analytics")]),_c('p',[_vm._v(" Linking your Facebook Analytics account allows us to retrieve information regarding your web traffic. It is completely safe and secure, and we will only have access to the data we need for our services to work. ")]),_c('p',[_vm._v(" When you click the link, you will be asked to login to Facebook so that we can create a token to access data on your behalf. ")]),_c('p',[_vm._v(" For your protection, we will only request access to "),_c('b',[_vm._v("View your Facebook Analytics data with (Read-only) permissions.")])]),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","disabled":_vm.$store.state.linearAdmin.linearAdminError || _vm.$store.state.linearAdmin.googleAnalyticsError},on:{"click":function($event){return _vm.login()}}},[_vm._v("Link your account")])],1),_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(_vm.authChecked && !_vm.loggedIn),expression:"authChecked && !loggedIn"}]},[_c('div',{staticClass:"ml-2",attrs:{"id":"google-button"}})])],1):_vm._e(),(
                      _vm.$store.state.linearAdmin.googleAnalyticsError ||
                      _vm.$store.state.linearAdmin.linearAdminError ||
                      _vm.error
                    )?_c('v-card-actions',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.tryAgain}},[_vm._v("Try Again")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.themeColor)?_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"rotate":-90,"size":100,"width":15,"value":_vm.error ? 1 : _vm.value,"color":_vm.error ? 'error' : _vm.themeColor}},[_c('v-fade-transition',{attrs:{"leave-absolute":""}},[(_vm.value === 0)?_c('v-icon',{attrs:{"color":_vm.themeColor}},[_vm._v("link")]):(_vm.value === 100)?_c('v-icon',{attrs:{"color":_vm.themeColor}},[_vm._v("check")]):_c('span',[_vm._v(_vm._s(_vm.value))])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1),_c('main-footer',{staticClass:"reversed"}),_c('app-globals')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }